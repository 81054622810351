<!-- 在线留言 -->
<template>
    <div class="OnlineMsg">
        <!-- 查询表单 -->
        <el-form ref="form" :model="form" inline class="form" label-width="100px">
            <el-form-item label="查询日期">
                <el-date-picker
                    value-format="yyyy-MM-dd"
                    v-model="form.dateQuery"
                    type="daterange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期">
                </el-date-picker>
            </el-form-item>
            <el-form-item label="搜索">
                <el-input v-model="form.search" placeholder="请输入关键字"></el-input>
            </el-form-item>
            <div class="" style="margin-left: 22px; margin-top: 5px;">
                <el-button size="small" style="background: #18BC9C;color: #FFFFFF;" @click="Query">
                    查询
                </el-button>
                <el-button size="small" style="background: #FAFAFA;color: #666666;" @click="Reset">
                    重置
                </el-button>
            </div>
        </el-form>
        <div class="" style="margin-left:22px ;">
            <el-button size="small" icon="el-icon-refresh" 
                style="background-color: #2C3E50; border-radius: 3px;"
                @click="refresh">
                <!-- 刷新 -->
            </el-button>
            <el-button size="small" icon="el-icon-plus" style="background: #18BC9C;color: #FFFFFF;"
                @click="newlyAdded">
                发表留言
            </el-button>
        </div>
        <!-- 表格 -->
        <el-table :data="tableData" border stripe style="width:97.9%;flex: 1;" height="650" >
            <el-table-column type="selection" align="center" width="50">
            </el-table-column>
            <el-table-column prop="add_time" label="时间" align="center" width="200">
            </el-table-column>
            <!-- <el-table-column prop="" label="姓名" align="center" width="120">
            </el-table-column> -->
            <el-table-column prop="content" label="内容" align="center" >
            </el-table-column>
            <!-- <el-table-column  label="操作" align="center" fixed="right" width="200">
                <template slot-scope="scope">
                    <el-button icon="el-icon-view" size="mini" style="background:#18BC9C;color: #fff;"
                    @click="details(scope.$index, scope.row)">
                        详情
                    </el-button>
                    <el-button  icon="el-icon-delete" size="mini" style="background:#E74C3C;color: #fff;"
                    @click="del(scope.$index, scope.row)">
                        删除
                    </el-button>
                </template>
            </el-table-column> -->
        </el-table>
        <!--分页 -->
        <el-pagination @size-change="handleSizeChange" 
        @current-change="handleCurrentChange" :current-page="currentPage"
            :page-sizes="[20,50,100,200,500,1000]" :page-size="10" background
            layout="total, sizes, prev, pager, next, jumper" :total="total">
        </el-pagination>
    </div>
</template>

<script>
    export default{
        data() {
            return{
                form:{
                   dateQuery:"",//查询日期
                   search:""//搜索
                },
                tableData:[],
                currentPage: 1,
                currentLimit: 20,
                total: 0,
            }
        },
        created() {
            this.getList()
            this.$emit("msg",JSON.parse(localStorage.getItem("get_info")))
        },
        activated() {
            this.getList()
        },
        methods:{
            getList(){//获取列表
                this.$request({url:'/api/mechanismexam/messageList',method:'POST',data:{
                    uid:localStorage.getItem('token'),
                    dateQuery:this.form.dateQuery,
                    search:this.form.search,
                    page:this.currentPage,
                    limit:this.currentLimit
                }}).then(res=>{
                    console.log(res)
                    this.tableData=res.data.list
                    this.total=res.data.count
                })
            },
            handleSizeChange(val) {
                // console.log(`每页 ${val } 条`);
                this.currentLimit=val
                this.getList()
            },
            handleCurrentChange(val) {
                // console.log(`当前页: ${val}`);
                this.currentPage=val
                this.getList()
            },
            // 刷新
            refresh() {
                this.$router.replace({
                    path: "/newpage", //空页面路由
                    query: {
                        path: this.$route.path
                    }
                })
            },
            newlyAdded() { //发表留言
                this.$router.replace({
                    path: "/publishMsg"
                })
            },
            details(index,row){//详情
                this.$router.replace({
                    path: "/msgDetails",
                    query: {
                        path: row.id
                    }
                })
            },
            del(index,row){//删除
                
            },
            Query(){//查询
                // console.log(this.form)
                this.currentPage=1
                this.getList()
            },
            Reset(){//重置
                this.form.dateQuery=''//查询日期
                this.form.search=''//搜索
            },
            
        }
    }
</script>

<style scoped="scoped">
    .OnlineMsg{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
    }
    /* 查询部分 */
    .form {
        padding-top: 34px;
        display: flex;
        /* justify-content: space-between; */
        flex-wrap: wrap;
    }
    
    .form ::v-deep .el-input__inner {
        width: 276px;
        height: 37px;
    }
    
    .form ::v-deep .el-form-item__label {
        color: #000000;
    }
    /* 按钮 */
    ::v-deep .el-icon-refresh {
        font-weight: bold !important;
        color: #FFFFFF;
    }
    
    ::v-deep .el-icon-plus {
        font-weight: bold !important;
    }
    /* 表格 */
    .el-table {
        margin: 20px;
    }
    
    ::v-deep .el-table__cell {
        color: #000;
        padding: 0;
        height: 35px;
    }
    /* 分页 */
    .el-pagination {
        margin: 20px;
        margin-top: 0px;
    }
    ::v-deep.el-pagination.is-background .el-pager li:not(.disabled).active{
        background-color: #2C3E50 !important;
    }
</style>
